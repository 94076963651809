import '../scss/style.scss';
import { Carousel } from 'bootstrap';


const burger = document.querySelector('.burger');
const header = document.querySelector('.header');
const overlay = document.querySelector('.overlay');
const menu = document.querySelector('.header__navigation');
const closeBtn = document.querySelector('.close-btn');

function toggleMenu() {
    menu.classList.toggle('shown');
    overlay.classList.toggle('shown');
    header.classList.toggle('header_fixed');
}

burger.addEventListener('click', toggleMenu);
overlay.addEventListener('click', toggleMenu);
closeBtn.addEventListener('click', toggleMenu);
